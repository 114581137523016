import { landingConstants } from "../constants";

const defaultState = {};

export default function landing(state = defaultState, action) {
  switch (action.type) {
    case landingConstants.LANDINGPAGE_SELECTED:
      return { ...state, landing: action.landing };
    default:
      return { ...state };
  }
}
