import { combineReducers } from "redux";
import language from "./language.reducer";
import landing from "./landing.reducer";
import account from "./account.reducer";
import swapcontract from "./swapcontract.reducer";
import tokencontract from "./tokencontract.reducer";
import tranfer from "./tranfer.reducer";
import confirmation from "./confirmation.reducer";
import conditions from "./conditions.reducer";
import swap from "./swap.reducer";
import chain from "./chain.reducer"

const rootReducer = combineReducers({
  landing: landing,
  account: account,
  swapcontract: swapcontract,
  tokencontract: tokencontract,
  language: language,
  tranfer: tranfer,
  confirmation: confirmation,
  conditions: conditions,
  swap: swap,
  chain: chain
});

export default rootReducer;
