import { tokencontractConstants } from "../constants";

const defaultState = {};

export default function tokencontract(state = defaultState, action) {
  switch (action.type) {
    case tokencontractConstants.TOKENCONTRACT_SELECTED:
      return { ...state, ...action.tokencontract };
    default:
      return { ...state };
  }
}
