import { accountConstants } from "../constants";

const defaultState = {};

export default function account(state = defaultState, action) {
  switch (action.type) {
    case accountConstants.ACCOUNT_SELECTED:
      return { ...state, address: action.account };
    default:
      return { ...state };
  }
}
