import { swapcontractConstants } from "../constants";

const defaultState = {};

export default function swapcontract(state = defaultState, action) {
  switch (action.type) {
    case swapcontractConstants.SWAPCONTRACT_SELECTED:
      return { ...state, ...action.swapcontract };
    default:
      return { ...state };
  }
}
