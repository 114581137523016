const amplifyConfig = {
  API: {
    endpoints: [
      {
        name: "*",
        endpoint: process.env.REACT_APP_API_ENDPOINT_URL,
        region: process.env.REACT_APP_AMP_COG_REGION,
      },
    ],
  },
};

export { amplifyConfig };
