export const swapConstants = {
  STELLAR: "1",
  KLAY: "2",
  BSC: "3",
  SWAP_SELECTED: "SWAP_SELECTED",
  SWAP_BSC_SUCCESS: "SWAP_BSC_SUCCESS",
  SWAP_BSC_RESET: "SWAP_BSC_RESET",
  SWAP_KLAY_SUCCESS: "SWAP_KLAY_SUCCESS",
  SWAP_KLAY_RESET: "SWAP_KLAY_RESET",
};
