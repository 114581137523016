import { swapConstants } from "../constants";

const defaultState = {};

export default function swap(state = defaultState, action) {
  switch (action.type) {
    case swapConstants.SWAP_SELECTED:
      return { ...state, ...action };
    case swapConstants.SWAP_BSC_SUCCESS:
      return { ...state, plnBscSwapTx: action.plnBscSwapTx };
    case swapConstants.SWAP_BSC_RESET:
      return { ...state, plnBscSwapTx: undefined };
    case swapConstants.SWAP_KLAY_SUCCESS:
      return { ...state, plnKlaySwapTx: action.plnKlaySwapTx };
    case swapConstants.SWAP_KLAY_RESET:
      return { ...state, plnKlaySwapTx: undefined };
    default:
      return { ...state };
  }
}
