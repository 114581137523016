import { chainConstants } from "../constants";

const defaultState = {
  chain: {
    chain: "BNB",
    fromChain: "",
    toChain: "",
    fromId: "",
    toId: "",
  },
};

export default function chain(state = defaultState, action) {
  switch (action.type) {
    case chainConstants.CHAIN:
      return { ...state, ...action };
    default:
      return state;
  }
}
